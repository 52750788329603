<template>
  <v-row>
    <v-dialog v-model="HintPinada" scrollable persistent max-width="700px">
      <v-card>
        <v-toolbar dense flat
          ><h3>Petunjuk Permainan</h3>
          <v-spacer></v-spacer>
          <h4 v-if="!close">Silakan tunggu... {{ counting }}</h4>
          <v-btn text v-if="close" @click="$emit('false')"
            >tutup <v-icon color="red">mdi-close keluar</v-icon></v-btn
          >
        </v-toolbar>
        <div class="pa-5 d-flex flex-column align-center">
          <p>
            Pada permainan ini Anda diharuskan untuk mencari kata pada deretan
            kotak sesuai dengan daftar kata yang ada di sebelah kanan.
            <b>Klik</b> dan <b>geser</b> kata yang telah ditemukan.
          </p>
          <img
            src="../../../assets/img/PinadaView.jpeg"
            height="300px"
            alt=""
          />
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "PinadaHint",
  props: ["HintPinada"],
  data() {
    return {
      close: false,
      counting: null
    };
  },
  mounted() {
    this.timerClose();
  },
  methods: {
    timerClose() {
      let sec = 15;
      console.log(sec);
      let min = setInterval(() => {
        sec--;
        this.counting = sec;
        if (sec === 0) {
          this.close = true;
          clearInterval(min);
        }
      }, 1000);
    }
  }
};
</script>

<style></style>
